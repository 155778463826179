import { useEffect, useState } from "react";
// @mui
import { Box, Link as MuiLink } from "@mui/material";
import { usePage } from "@inertiajs/react";
import { useSnackbar } from "notistack";
import Main from "./Main";
import useResponsive from "@/hooks/useResponsive";
import EmployerHeader from "./header/EmployerHeader";
import NavVertical from "./nav/NavVertical";
import Ably from "ably/promises";

export default function EmployerPortalLayout({ children }) {
    const {
        auth: { user },
        flash,
        reminder = [],
    } = usePage().props;
    const { enqueueSnackbar } = useSnackbar();
    const [notifications, setNotifications] = useState(user?.notifications);
    // const isDesktop = useResponsive("up", "lg");
    // const isTab = useResponsive("up", "md");
    // const isMobile = useResponsive("up", "sm");
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const ably = new Ably.Realtime.Promise({
            key: "VIpnQg.BpSDLQ:IVdEk5nuOhDmdRPJB3fs1Nuicbiv34XOkBwvbvkLRCM",
            clientId: user.id.toString(),
        });
        const channel = ably.channels.get(`private:user.${user.id}`);

        channel.subscribe("message", (message) => {
            enqueueSnackbar(message.data.message);
        });

        return () => {
            channel.unsubscribe();
        };
    }, [user]);
    
    useEffect(() => {
        if (flash && flash.message) { // Ensure flash exists before accessing properties
            enqueueSnackbar(flash.message, {
                variant: flash.type || "default",
            });
        }
    }, [flash]);

    useEffect(() => {
        if (reminder && user) {
            const reminders = reminder.filter((rem) => rem?.message);
            (reminders || [])?.forEach((rem) => {
                enqueueSnackbar(rem.message, {
                    variant: rem.type || "default",
                    preventDuplicate: true,
                    autoHideDuration: 9000,
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                });
            });
        }
    }, [reminder, user]);

    return (
        <>
            {/* Header */}
            <EmployerHeader
                user={user}
                onOpenNav={handleOpen}
                notifications={notifications}
            />
            {/* END HEADER */}
            <Box
                sx={{
                    display: { lg: "flex" },
                    minHeight: { lg: 1 },
                }}
            >
                <NavVertical openNav={open} onCloseNav={handleClose} />
                <Main>{children}</Main>
                {/* FOOTER */}
                {/* <Stack
                    alignItems={"center"}
                    sx={{
                        borderTop: "1px solid #cddfff",
                        transition: "all 0.3s",
                    }}
                >
                    <Typography
                        sx={{ marginTop: 2, fontFamily: "Nunito, sans-serif" }}
                    >
                        <Typography
                            variant="body2"
                            component="span"
                            sx={{ fontFamily: "Nunito, sans-serif" }}
                        >
                            © Copyright 2023 |{" "}
                            <Typography
                                component="span"
                                sx={{
                                    fontWeight: 700,
                                    fontFamily: "Nunito, sans-serif",
                                }}
                            >
                                Upcare
                            </Typography>
                            . | All Rights Reserved
                        </Typography>
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{
                            marginBottom: 3,
                            fontFamily: "Nunito, sans-serif",
                        }}
                    >
                        Designed by{" "}
                        <MuiLink href="https://infinityhub.com/">
                            InfinityHub
                        </MuiLink>
                    </Typography>
                </Stack> */}
                {/* END FOOTER */}
            </Box>
        </>
    );
}
